import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import Seo from '../components/Seo'
import NavbarGlobal from '../components/NavbarGlobal'
import FooterGlobal from '../components/FooterGlobal'
import { FaCheckCircle } from "react-icons/fa"

export default function FizjoterapiaBoluPlecow() {
  return (

    <div className='layout'>

      <Seo
        title="Fizjoterapia Bólu Pleców"
        description="Fizjoterapia dla cierpiących z powodu bólu pleców, którzy chcą trwale uwolnić się od bólu i wrócić do zadowalającego poziomu sprawności."
        keywords="fizjoterapia, ból pleców"
        url="https://www.fizjopersonalny.pl/fizjoterapia-bolu-plecow"
      />

      <div className='header_gradient'>
        <NavbarGlobal />
        <div className='offer_box'>

          <div className='offer_intro_header'>
            <h1>Fizjoterapia bólu pleców</h1>
            <span>Fizjoterapia skupiona na trwałym efekcie</span>
            <p>Fizjoterapia dla cierpiących z powodu bólu pleców, którzy chcą trwale uwolnić się od bólu i wrócić do zadowalającego poziomu sprawności.</p>
            <ul>
              <li><FaCheckCircle />szczegółowe badanie</li>
              <li><FaCheckCircle />indywidualny plan terapii</li>
              <li><FaCheckCircle />długoterminowe wsparcie</li>
            </ul>
            <div className='offer_intro_buttons'>
              <Link to="/kontakt" className='button'>Umów wizytę</Link>
              <Link to="#wiecej" className='button offer_intro_button'>dowiedz się więcej</Link>
            </div>
          </div>

          <div className='offer_intro_image'>
            <StaticImage src="../images/offer/fizjoterapia-bol-plecow.jpg" alt="Fizjoterapia i Trening Personalny | Warszawa"/>
          </div>

        </div>
      </div>
      
      <div className='content'>
      
        <section id="wiecej">
          <div className='offer_content'>
            <h2>Fizjoterapia indywidualna poprawia funkcjonowanie z bólem pleców</h2>
            <span>Codzienne funkcjonowanie bez bólu pleców</span>
            <p>Znasz to uczucie, gdy codzienne czynności sprawiają problem. Wstawanie z łóżka potrafi zaboleć, a poranna sztywność pleców wydaje się być normą. Praca przy biurku i stres dokręcają spiralę napięcia w plecach. Sprzątanie to męczarnia, nie wspominając o ubieraniu się czy toalecie.</p>

            <div className='offer_client'>
              <ul>
                <li>
                  <h3>wstawanie z łóżka</h3>
                  <StaticImage src="../images/offer/lozko-bol-plecow.jpg" alt="Ból pleców podczas wstawania z łóżka"/>
                </li>
                <li>
                  <h3>praca przy biurku</h3>
                  <StaticImage src="../images/offer/biurko-bol-plecow.jpg" alt="Ból pleców podczas siedzenia przy biurku"/>
                </li>
                <li>
                  <h3>sprzątanie mieszkania</h3>
                  <StaticImage src="../images/offer/sprzatanie-bol-plecow.jpg" alt="Ból pleców podczas sprzątania"/>
                </li>
              </ul>
            </div>

            <p>W wyniku dwóch kontuzji, doświadczyłem bólu pleców na własnej skórze (przepukliny L4/L5/S1). Przeszywający ból, usztywnienie ciała, mrowienie uda, niemożność założenia skarpetki - te doświadczenia uczą pokory w postępowaniu z własnym ciałem. Możesz mi jeszcze nie wierzyć, ale ból pleców można ujarzmić.</p>
            <div className='offer_button'>
              <Link to="/kontakt" className='button'>Umów wizytę</Link>
            </div>
          </div>
        </section>
      
        <section>
          <div className='offer_content offer_content_background'>
            <h2>Fizjoterapia w opinii pacjentów</h2>
            <span>Rekomendacje osób, które zdecydowały się na współpracę</span>
            <p>Przedstawione poniżej rekomendacje stanowią część opinii, które możesz znaleźć w takich miejscach jak: <a href="https://goo.gl/maps/4H6dS7wtz8Ue3JSh9" target="_blank" rel="noreferrer">Google Maps</a>, <a href="https://www.facebook.com/FizjoPersonalny/" target="_blank" rel="noreferrer">Facebook</a>, <a href="https://www.instagram.com/FizjoPersonalny/" target="_blank" rel="noreferrer">Instagram</a> czy <a href="https://www.znanylekarz.pl/lukasz-kalenczuk/fizjoterapeuta/warszawa" target="_blank" rel="noreferrer">Znany Lekarz</a>.</p>
            <div className='offer_feed'>
              <ul>
                <li>
                  <StaticImage src="../images/feed/gm-4.png" alt="Fizjoterapia i Trening Personalny | Warszawa"/>
                </li>
                <li>
                  <StaticImage src="../images/feed/fb-4.png" alt="Fizjoterapia i Trening Personalny | Warszawa"/>
                </li>
                <li>
                  <StaticImage src="../images/feed/gm-9.png" alt="Fizjoterapia i Trening Personalny | Warszawa"/>
                </li>
              </ul>
            </div>
            <div className='offer_button'>
              <Link to="/kontakt" className='button'>Umów wizytę</Link>
            </div>
          </div>
        </section>
      
        <section>
          <div className='offer_content'>
            <h2>Fizjoterapia wielowymiarowa to większa efektywność</h2>
            <span>Indywidualnie zaplanowany proces poprawy sprawności</span>
            <p>Na bazie wieloletnich doświadczeń stworzyłem 4-stopniowy proces poprawy sprawności dla osób cierpiących z powodu bólu pleców. Zaczniemy od punktu gdzie trudno jest Ci wstać z łóżka, ubrać się czy zawiązać buta do momentu, gdy osiągniesz pełną niezależność ruchową.</p>
            
            <div className='offer_physio_protocol'>
              <ul>
                <li>
                  <h3>likwidacja bólu</h3>
                  <p>Identyfikacja wyzwalaczy bólu. Techniki i metody fizjoterapeutyczne. Narzędzia autoterapeutyczne.</p>
                </li>
                <li>
                  <h3>poprawa kontroli</h3>
                  <p>Nauka świadomego napinania mięśni. Optymalizacja procesu oddychania. Poprawa postawy ciała.</p>
                </li>
                <li>
                  <h3>wytrzymałość mięśni</h3>
                  <p>Indywidualnie dobrane ćwiczenia. Doskonalenie techniki poruszania się. Progresywny plan treningowy.</p>
                </li>
                <li>
                  <h3>rozwój sprawności</h3>
                  <p>Poprawa wyglądu sylwetki. Zwiększenie wydolności oddechowej. Zwiększenie siły mięśniowej.</p>
                </li>
              </ul>
            </div>

            <p>Fizjoterapia bólu pleców wykorzystująca wielokierunkowe podejście pozwoli Ci osiągnąć trwały efekt. Twoje aktywne zaangażowanie w proces terapeutyczny sprawi, że pozbędziesz się bólu, wzmocnisz plecy i zwiększysz sprawność. Daj sobie szansę na życie bez bólu.</p>
            <div className='offer_button'>
              <Link to="/kontakt" className='button'>Umów wizytę</Link>
            </div>
          </div>
        </section>

      </div>
      <FooterGlobal />
    </div>

  )
}